$color-background: #000b2e;
$color-foreground: #fff;
$color-accent: #7a27ff;
$color-shadow: rgba(0, 0, 0, 0.075);
$color-mark: rgba(0, 0, 0, 0.125);
$opacity-dim: 0.6;
$opacity-pale: 0.2;
$color-foreground-dim: rgba($color-foreground, $opacity-dim);
$color-accent-dim: rgba($color-accent, $opacity-dim);
$color-accent-pale: rgba($color-accent, $opacity-pale);
$font-family-stack: 'Inter', 'Roboto', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
$font-weight: 700;
$duration-instant: 0.1s;
$duration-short: 0.2s;
$duration-medium: 0.4s;
$duration-long: 1s;
$breakpoint-width-tablet: 38rem;
$breakpoint-width-laptop: 78rem;
$breakpoint-width-desktop: 118rem;
$timing-function-standard: cubic-bezier(0.4, 0, 0.2, 1);

:root {
    --color-background: #{$color-background};
    --color-foreground: #{$color-foreground};
    --color-accent: #{$color-accent};
    --color-shadow: #{$color-shadow};
    --color-mark: #{$color-mark};
    --opacity-dim: #{$opacity-dim};
    --opacity-pale: #{$opacity-pale};
    --color-foreground-dim: #{$color-foreground-dim};
    --color-accent-dim: #{$color-accent-dim};
    --color-accent-pale: #{$color-accent-pale};
    --font-family-stack: #{inspect($font-family-stack)};
    --font-weight: #{$font-weight};
    --duration-instant: #{$duration-instant};
    --duration-short: #{$duration-short};
    --duration-medium: #{$duration-medium};
    --duration-long: #{$duration-long};
    --timing-function-standard: #{$timing-function-standard};
}

::selection {
    background: var(--color-accent);
    color: var(--color-foreground);
}

html,
body {
    height: 100%;
    cursor: auto;
}

body {
    margin: 0;
    background: var(--color-background);
    color: var(--color-foreground);
    font-family: var(--font-family-stack);
    font-weight: var(--font-weight);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: inherit;
}

h1 {
    margin: 1rem 0;
    font-size: 1.75rem;
    font-style: italic;
    text-shadow: 0 0 0.033em #fff, 0 0 0.08em #fff, 0 0 0.1em var(--color-accent), 0 0 0.2em var(--color-accent),
        0 0 0.3em var(--color-accent), 0 0 1em var(--color-accent), 0 0 1.5em var(--color-accent);
}

h2 {
    margin: 0;
    font-style: italic;
}

button,
input {
    border: none;
    font-size: 1.5rem;
    text-overflow: ellipsis;
}

button {
    transition: border-radius var(--duration-short) var(--timing-function-standard);
    border-radius: 0.5rem;
}

input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

input,
.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
}

#root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
}
